import React, { PropsWithChildren } from "react";

interface Props {
  icon: (args: any) => JSX.Element;
  name: string;
}

export const IconBlock = ({
  icon: Icon,
  name,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <li className="mt-10 md:mt-0">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <div className="flex items-center justify-center h-12 w-12 rounded-md bg-lead-500 text-white">
            <Icon className="w-6 h-6" />
          </div>
        </div>
        <div className="ml-4">
          <h5 className="text-lg leading-6 font-medium text-gray-900">
            {name}
          </h5>
          {children && (
            <p className="mt-1 text-base leading-6 text-gray-300">{children}</p>
          )}
        </div>
      </div>
    </li>
  );
};
