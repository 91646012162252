import * as React from "react";
import { Background } from "../components/Background";
import { Features } from "../components/congress/Features";
import { Hero } from "../components/congress/Hero";
import { Agenda } from "../components/congress/Agenda";
import { Ticket } from "../components/congress/Ticket";
import { Footer } from "../components/Footer";
import { Helmet } from "react-helmet";
import { Topics } from "../components/congress/Topics";

const CongressPage = () => {
  return (
    <>
      <Helmet
        title="Międzynarodowy Kongres Smart Production"
        description="Międzynarodowy Kongres Smart Production"
      >
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
        <link rel="icon" type="image/png" href="/icon.png" />
      </Helmet>

      <title>Międzynarodowy Kongres Smart Production</title>

      <Background />

      <main className="isolate mx-auto px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl ">
        <Hero />
        <Topics />
        <Agenda />
        <Ticket />
        <Features />
        <Footer />
      </main>
    </>
  );
};

export default CongressPage;
