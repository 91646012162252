import * as React from "react";
import { AgendaItem } from "../AgendaItem";
import { AgendaSection } from "../AgendaSection";

export const Agenda = () => (
  <>
    <AgendaSection title="Agenda" date="28 lutego 2023" hours="09:00 - 16:00">
      <AgendaItem
        time="09:00"
        person="Henryk Metz"
        position="Expert / Project Manager, Carrywater Group"
      >
        Industry 4.0
      </AgendaItem>
      {/* <AgendaItemBreak time="8:40 - 9:10" /> */}
      <AgendaItem
        time="10:00"
        person="dr Maciej Pieńkowski"
        position="Lean and Operational Excellence Specialist, Colin Aerospace"
      >
        Grywalizacja jako wsparcie w koncepcji Lean Management
      </AgendaItem>
      <AgendaItem
        time="11:00"
        person="Tomasz Szczotka"
        position="Management Process Owner, IBM"
      >
        Project transformation critical factors
      </AgendaItem>
      <AgendaItem time="12:00">Lunch break</AgendaItem>
      <AgendaItem time="12:45">
        <div>
          Panele tematyczne:
          <ul className="list-disc list-inside">
            <li>Smart Production</li>
            <li>Digital Transformation</li>
            <li>Industry 4.0</li>
          </ul>
        </div>
      </AgendaItem>
      <AgendaItem
        time="13:45"
        person="Rafał Bernatek"
        position="Performance Improvement Ernst & Young"
      >
        Jak miękkie kompetencje wpływają na twarde wyniki?
      </AgendaItem>
      <AgendaItem
        time="14:45"
        person="Kaori Nishimoto"
        position="Quality Lider IKEA Group"
      >
        Gemba Kaizen
      </AgendaItem>
      <AgendaItem time="16:00">Zakończenie kongresu</AgendaItem>
      <AgendaItem time="19:00">Uroczysta kolacja (pakiet VIP)</AgendaItem>
    </AgendaSection>

    {/* <div className="mt-6 py-12">
      <h4 className="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl mb-8">
        Kolejne spotkanie
      </h4>

      <p>
        Kolejne spotkanie z cyklu Academy Smart Production odbędzie się 19
        listopada 2022 roku (8:00 - 10:00) w Tychach. Więcej szczegółów i agenda
        wkrótce.
      </p>
    </div> */}
  </>
);
