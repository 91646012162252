import * as React from "react";

export const Ticket = () => {
  return (
    <div className="py-12 md:py-16 lg:py-20">
      <h2 className="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
        <span className="block">Zainteresowany?</span>
        <span className="block text-lead-500">
          Zakup bilet i weź udział w konferencji.
        </span>
      </h2>
      <div className="mt-8 flex">
        <div className="inline-flex rounded-md shadow">
          <a
            href="https://l3auw7abgcg.typeform.com/to/iHXgep6r"
            target="_blank"
            rel="noopener"
            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-lead-500 hover:bg-lead-600"
          >
            Kup Bilet (890zł)
          </a>
        </div>
      </div>
      <div className="mt-8">
        <h4 className="text-sm font-medium text-gray-700">Zostań wystawcą:</h4>
        <a
          href="mailto:kongres@smart-production.pl"
          className="text-lead-600 hover:underline"
        >
          kongres@smart-production.pl
        </a>
        <h4 className="mt-6 text-sm font-medium text-gray-700">
          Masz zaproszenie? Zarejestruj swój bilet:
        </h4>
        <a
          href="mailto:bilety@smart-production.pl"
          className="text-lead-600 hover:underline"
        >
          bilety@smart-production.pl
        </a>
      </div>
    </div>
  );
};
