import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const Hero = () => {
  return (
    <div className="mt-16 sm:mt-24">
      <div className="sm:text-center md:mx-auto lg:text-left">
        <div className="flex gap-8 items-center">
          <StaticImage
            src="../images/logo-asp.png"
            alt="Academy Smart Production"
            width={241}
            height={50}
            placeholder="none"
          />
        </div>
        <h1 className="mt-12">
          <span className="block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
            <span className="block text-gray-900">Międzynarodowy Kongres</span>
            <span className="block md:mt-1.5 text-lead-500">
              Smart Production
            </span>
          </span>
        </h1>
        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg max-w-5xl">
          III Edycja
          <br />
          28.02.2023, Katowice, Poland
        </p>
      </div>
      <div className="mt-8 relative">
        <StaticImage
          className="w-full rounded-xl"
          src="../../images/kongres.jpg"
          alt="Kongres"
          width={1400}
          height={440}
          transformOptions={{
            fit: "cover",
            cropFocus: "center",
          }}
        />
      </div>
      <div className="flex flex-col md:flex-row w-auto md:items-end mt-8">
        <div className="pr-8">
          <h4 className="mb-2.5 text-xs font-medium text-gray-500">
            Partner główny:
          </h4>
          <StaticImage
            src="../../images/logo-ps.png"
            alt="Politechnika Śląska"
            width={186}
            height={50}
            placeholder="none"
          />
        </div>
        <div className="mt-6 md:pl-8 md:mt-0">
          <h4 className="mb-2.5 text-xs font-medium text-gray-500">
            Partnerzy:
          </h4>
          <StaticImage
            src="../../images/lean-technology.jpg"
            alt="Lean Technology"
            width={87}
            height={50}
            placeholder="none"
            className="mr-8 mb-2 md:mb-0"
          />
          <StaticImage
            src="../../images/lean-center.png"
            alt="LeanCenter"
            width={67}
            height={50}
            placeholder="none"
            className="mr-8 mb-2 md:mb-0"
          />
          <StaticImage
            src="../../images/tagatic.png"
            alt="Tagatic"
            width={165}
            height={50}
            placeholder="none"
          />
        </div>
      </div>
    </div>
  );
};
