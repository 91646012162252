import * as React from "react";
import {
  GlobeAltIcon,
  LightningBoltIcon,
  ScaleIcon,
} from "@heroicons/react/outline";

const features = [
  {
    name: "Zdobywanie wiedzy",
    description:
      "Poznasz wartościowe praktyki pochodzące z międzynarodowych firm i organizacji. Case studies pozwolą Ci w ciekawy sposób poszerzyć wiedzę.",
    icon: GlobeAltIcon,
  },
  {
    name: "Wymiana doświadczeń",
    description:
      "Wymienisz się doświadczeniem z zakresu Lean Production i nowoczesnych metod zarządzania w produkcji oraz Industry 4.0.",
    icon: ScaleIcon,
  },
  {
    name: "Budowanie relacji",
    description:
      "Spotkania, rozmowy i dyskusje pozwolą Ci na poznanie nowych osób i zbudowanie relacji biznesowych.",
    icon: LightningBoltIcon,
  },
];

export const Features = () => {
  return (
    <>
      <div className="py-12">
        <h4 className="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl mb-12">
          Dlaczego warto wziąć udział?
        </h4>
        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
          {features.map((feature) => (
            <div key={feature.name}>
              <dt>
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-lead-500 text-white">
                  <feature.icon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg leading-6 font-medium text-gray-900">
                  {feature.name}
                </p>
              </dt>
              <dd className="mt-2 text-base text-gray-500">
                {feature.description}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </>
  );
};
