import * as React from "react";
import { IconBlock } from "../IconBlock";

import { ArrowRightIcon } from "@heroicons/react/outline";

export const Topics = () => {
  return (
    <div className="mt-12 md:mt-20 pt-12 pb-12 bg-lead-50/70 rounded-2xl">
      <div className="container">
        <div className="lg:text-center">
          <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Zagadnienia Kongresu
          </h3>
          <p className="mt-4 max-w-2xl text-lg leading-7 text-gray-700 lg:mx-auto">
            Zapraszamy do udziału w kongresie osoby zainteresowane zagadnieniami
            Lean Production oraz nowoczesnych metod zarządzania.
          </p>
        </div>

        <div className="mt-2 md:mt-8 mx-auto max-w-4xl">
          <ul className="md:grid md:grid-cols-2 gap-6 p-2 md:p-8">
            <IconBlock icon={ArrowRightIcon} name="Industry 4.0" />
            <IconBlock icon={ArrowRightIcon} name="Lean Management" />
            <IconBlock icon={ArrowRightIcon} name="Kaizen Improvement" />
            <IconBlock
              icon={ArrowRightIcon}
              name="Total Productive Maintenance"
            />
            <IconBlock icon={ArrowRightIcon} name="Lean Product Development" />
            <IconBlock icon={ArrowRightIcon} name="Industry Gamification" />
          </ul>
        </div>
      </div>
    </div>
  );
};
